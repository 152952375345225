










































































































import { Component, Vue, Watch  } from "vue-property-decorator"
import topNav from "../../components/topNav.vue"

interface orderListParams {
  [idx: string]: any
}

@Component({
	components: {
		topNav,
	},
	filters: {
		itemConsumePoints(value: any) {
			const priceFloat = value.toString().split(".")[1]
			if (priceFloat) {
				return value
			} else {
				return value + ".00"
			}
		},
	}
})
export default class Default extends Vue {
  externalUrl:string=''
  showBalloons: boolean = true
  isShare: boolean = false //弹窗
  priceShowStyle:number=0 
  goodsDetailsId:string=''
  // goGoodsDetailUrl:string=''
  isShowShipments: boolean = false
	redUnderLineNum: number = 0
	tabData: { title: string }[] = [
    {title: "全部"},
    {title: "待支付"},
    {title: "待发货"},
    {title: "发货中"},
    {title: "已完成"},
	]
	orderListParams: orderListParams = {
		PageIndex: 1,
		PageSize: 5
	}
	OrderListData: any[] = []
	addData: any = {}
	$refs!: {
		infiniteLoading: HTMLFormElement
	}
	@Watch("name")
	getWatchValue(newVal: string, oldVal: string) {
		// console.log(newVal, oldVal)
	}
  getOrderShipments(item:any){
    let timeStamp = item.createTime;
   if(item.category=='实物'&&item.validStatus==1&&item.deliveryStatus==0){
       let hh =new Date(timeStamp).getHours() < 10? "0" + new Date(timeStamp).getHours(): new Date(timeStamp).getHours();
        let week = new Date(timeStamp).getDay();
        let weeks = ["日","一","二","三","四","五","六"];
        let getWeek = weeks[week];
        // console.log("getOrderShipments",item,getWeek,hh);
        if((getWeek=="五" && hh>=17) || getWeek=="六" || getWeek=="日"){
          this.isShowShipments = true
        }else{
          this.isShowShipments = false
        }
     return this.isShowShipments
   }
  }
  getOrderStatus(item:any) {
    if(item.priceShowStyle!=3||(item.priceShowStyle==3&&item.validStatus==1)) {
      switch (item.deliveryStatus) {
        case 0:
          return '待发货'
        case 1:
          return '已发货'
        case 2:
          return '待收货'
      }
    }
    if(item.priceShowStyle==3) {
      switch (item.validStatus) {
        case 0:
          return '未支付'
        case 2:
          return '取消'
        case 3:
          return '失败'
        case 4:
          return '退款'
      }
    } 
  }
	changeTabItem(index: number) {
    this.orderListParams = {
      PageIndex: 1,
      PageSize: 5
	  }
		document.getElementsByClassName("orderRecord")[0].scrollTop = 0
    if (index == 0) {
      this.orderListParams.DeleveryStatus = -1
    }else if (index == 1) {
      this.orderListParams.OrderStatus = 0
    }else if (index == 2) {
      this.orderListParams.OrderStatus = 1
      this.orderListParams.DeleveryStatus = 0
    }else if (index == 3) {
      this.orderListParams.OrderStatus = 1
      this.orderListParams.DeleveryStatus = 2
    }else if (index == 4) {
      this.orderListParams.OrderStatus = 1
      this.orderListParams.DeleveryStatus = 1
    }
		this.redUnderLineNum = index
		console.log(this.OrderListData[this.redUnderLineNum])
		if (Object.keys(this.OrderListData[this.redUnderLineNum]).length == 0) {
			this.orderListParams.PageIndex = 1
			if (this.$refs.infiniteLoading) {
				this.$nextTick(() => {
					// console.log(this.$refs.infiniteLoading)
					this.$refs.infiniteLoading.$emit("$InfiniteLoading:reset")
				})
			}
		} else {
			this.orderListParams.PageIndex = this.OrderListData[this.redUnderLineNum].pageIndex++
		}

		// this.orderListParams.DeleveryStatus = index--
	}

	async created() {
		for (let i = 0; i < this.tabData.length; i++) {
			this.OrderListData.push({})
		}
    // 跳转query
    this.$nextTick(()=>{
      let tabIndex=this.$route.query.tab?this.$route.query.tab:0;
      this.changeTabItem(Number(tabIndex))
    })
	}

	mounted() {}
  openShareBox(item:any){
    const u: string = navigator.userAgent
		const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
		const isiOS: boolean = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
    var url = window.location.href;
    var domain = "https://m.esb.test-01.54test.cn"
    if(url.includes("eshebao")){
         domain = "https://m.eshebao.cn"
    }
    var goGoodsDetailUrl= domain+`/Home/PointsGoodsDetail/${item.goodsId}?priceShowStyle=${item.priceShowStyle}`
    var obj = {
      title:item.goodsName,
      description:`${item.consumePoints?`${item.consumePoints}积分+`:""}${item.totalPrices}元`,
      img:item.goodsCoverPic,
      href:goGoodsDetailUrl
    }
    // console.log("obj",obj,JSON.stringify(obj));
     if (isAndroid) {
          ;(window as any).eshebao.gotoShare(JSON.stringify(obj));
      } else if (isiOS) {
          ;(window as any).webkit.messageHandlers.gotoShare.postMessage(JSON.stringify(obj));
      }
  }
  historyBack(){
    let id = this.$route.query.id?this.$route.query.id:"";
    let priceShowStyle = this.$route.query.priceShowStyle?this.$route.query.priceShowStyle:0;
    if(id){
       this.$router.push(`/goodsDetails/${id}?priceShowStyle=${priceShowStyle}`)
    }else{
      this.$router.push(`/my`);
      // this.$router.go(-1);
    }
  }
  gopay(esbOrderId:string){
    const u: string = navigator.userAgent
		const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
		const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
		if (u.indexOf("eshebao") != -1) {
			if (isAndroid) {
				(window as any).eshebao.gotoPayOrder(esbOrderId)
			} else if (isiOS) {
				(window as any).webkit.messageHandlers.gotoPayOrder.postMessage(esbOrderId)
			}
		}
  }
  goOrder(type:number){
    const u: string = navigator.userAgent
		const isAndroid: boolean = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1 //android安卓
		const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios苹果
    if (type==1) {
      // console.log("社保");
			if (isAndroid) {
          (window as any).eshebao.gotoSocial()
      } else if (isiOS) {
          (window as any).webkit.messageHandlers.eshebao.postMessage("gotoSocial")
      }
    }else if(type==2){
    // console.log("健康体检");
    if (isAndroid) {
          (window as any).eshebao.gotoHomePage("3")
      } else if (isiOS) {
          (window as any).webkit.messageHandlers.gotoHomePage.postMessage("3")
      }
    }else if(type==3){
    // console.log("心理评测");
    if (isAndroid) {
          (window as any).eshebao.gotoEvaluation()
      } else if (isiOS) {
          (window as any).webkit.messageHandlers.eshebao.postMessage("gotoEvaluation")
      }
    }else if(type==4){
    // console.log("九价疫苗");
    if (isAndroid) {
        (window as any).eshebao.gotoNextPage(`${process.env.VUE_APP_BaseUrl4}/Home/Gardasil`)    
      } else if (isiOS) {
        (window as any).webkit.messageHandlers.gotoNextPage.postMessage(`${process.env.VUE_APP_BaseUrl4}/Home/Gardasil`)
      }
    }
  }
	getOrderList() {
		return new Promise((resolve, reject) => {
			this.$api.orderList(this.orderListParams).then((res: any) => {
				this.addData = res.data;
        for (let i=0; i<this.addData.page.length; i++) {
          this.$set(this.addData.page[i],'isShowCard',false)
        }
				resolve(true)
			})
		})
	}

	async onInfinite($state: any) {
		if (
			this.OrderListData[this.redUnderLineNum].length != 0 &&
			this.OrderListData[this.redUnderLineNum].hasNext == false
		) {
			$state.loaded()
			$state.complete()
		} else {
			await this.getOrderList()
			if (Object.keys(this.OrderListData[this.redUnderLineNum]).length == 0) {
				this.$set(this.OrderListData, this.redUnderLineNum, this.addData)
			} else {
				for (var key in this.addData) {
					if (key == "page") {
						this.OrderListData[this.redUnderLineNum].page = this.OrderListData[
							this.redUnderLineNum
						].page.concat(this.addData.page)
					} else {
						this.OrderListData[this.redUnderLineNum][key] = this.addData[key]
					}
				}
			}
			$state.loaded()
			if (this.OrderListData[this.redUnderLineNum].hasNext == false) {
				$state.complete()
			} else {
				this.orderListParams.PageIndex = ++this.OrderListData[this.redUnderLineNum]
					.pageIndex
			}
		}
	}
  copy(text:any) {
    const el = document.createElement('input')
    el.setAttribute('value', text)
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    this.showToast('复制成功')
  }
  showToast(val:any) {
    this.showBalloons = true
    let data: any = [val, this.showBalloons]
    this.VueFunc.$emit("balloons", data)
    setTimeout(() => {
      this.showBalloons = false
      data = [val, this.showBalloons]
      this.VueFunc.$emit("balloons", data)
    }, 2000)
  }
  async clickCard(item:any) {
    let res:any=await this.getOrderDetailInfo(item.esbOrderId);
    let orderCardInfo=res.data[0].getFuaEsbOrder.orderCardInfo;
    console.log(orderCardInfo,'orderCardInfo');
    item.orderCardInfo=orderCardInfo?orderCardInfo:[]
    item.isShowCard=!item.isShowCard;
  }
  getOrderDetailInfo(id:any) {
    return new Promise((resolve)=>{
      this.$api.getOrderDetailUI(id).then((res:any) =>{
        resolve(res)
      })
    })
  }
  goWuliu(item:any) {
    this.$router.push({
      path: "/my/logisticsInfo",
      query: {
        id:item.esbOrderId
      }
    })
  }
  editAddress(item:any) {
    console.log(item,'item');
    this.$router.push({
      path: "/my/receiverAddress",
      query: {
        orderId:item.orderId
      }
    })
  }
  goExternal(url:any) {
    this.externalUrl=url
  }
  closeExternalPage(){
    this.externalUrl=""
  }
}
